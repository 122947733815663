// src/VideoDetect.js

import React, { useState } from 'react';
import axios from 'axios';
import { API_BASE_URL } from './config'; // Ensure this is correctly set to your backend's base URL
import { Button, Spinner, Alert } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import CustomProgressBar from './CustomProgressBar'; // Import the custom ProgressBar

function VideoDetect() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [detectionResult, setDetectionResult] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const [processedVideoUrl, setProcessedVideoUrl] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    setDetectionResult('');
    setProcessedVideoUrl('');
    setErrorMessage('');
    setUploadProgress(0);
  };

  const handleDetect = () => {
    if (!selectedFile) {
      setErrorMessage('Please select a video file to check.');
      return;
    }

    const formData = new FormData();
    formData.append('video', selectedFile);

    setIsProcessing(true);
    setDetectionResult('');
    setProcessedVideoUrl('');
    setErrorMessage('');
    setUploadProgress(0);

    // Corrected Axios POST request to '/retrieve' instead of '/embed'
    axios.post(`${API_BASE_URL}/retrieve`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      withCredentials: true,
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        console.log(`Upload Progress: ${percentCompleted}%`); // For debugging
        setUploadProgress(percentCompleted);
      },
    })
    .then((response) => {
      if (response.data.uid) {
        setDetectionResult(`UID Detected: ${response.data.uid}`);
      } else {
        setDetectionResult('UID not found in the uploaded video.');
      }
    
      if (response.data.processed_video_url) {
        setProcessedVideoUrl(response.data.processed_video_url);
      } else {
        setProcessedVideoUrl(''); // Clear if not available
      }
    })
    .catch((error) => {
      if (error.response && error.response.data && error.response.data.error) {
        setErrorMessage(error.response.data.error);
      } else {
        setErrorMessage('An unexpected error occurred.');
      }
    })
    .finally(() => {
      setIsProcessing(false);
    });
  };

  return (
    <div className="container mt-5">
      <div className="card p-4 shadow">
        <h2 className="mb-4">Detect Watermark</h2>
        <div className="mb-3">
          <label htmlFor="videoDetect" className="form-label">
            Select Video File
          </label>
          <input
            type="file"
            accept="video/*"
            onChange={handleFileChange}
            id="videoDetect"
            className="form-control"
          />
        </div>
        <Button
          variant="primary"
          onClick={handleDetect}
          disabled={isProcessing}
        >
          {isProcessing ? 'Processing...' : 'Upload and Detect Watermark'}
        </Button>

        {/* Custom Progress Bar */}
        {uploadProgress > 0 && uploadProgress < 100 && (
          <div className="mt-3">
            <CustomProgressBar progress={uploadProgress} />
          </div>
        )}

        {/* Spinner */}
        {isProcessing && uploadProgress === 100 && (
          <div className="mt-3 text-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Processing...</span>
            </Spinner>
            <div>Processing your video...</div>
          </div>
        )}

        {/* Detection Result */}
        {detectionResult && (
          <Alert
            variant={detectionResult.startsWith('UID Detected') ? 'success' : 'warning'}
            className="mt-3"
          >
            {detectionResult}
          </Alert>
        )}

        {/* Video Player and Download Button */}
        {processedVideoUrl ? (
          <div className="mt-4">
          <h4>Processed Video:</h4>
          <video width="100%" height="auto" controls>
      <source src={processedVideoUrl} type="video/mp4" />
        Your browser does not support the video tag.
        </video>
        <Button
          variant="success"
          href={processedVideoUrl}
          download={`processed_${selectedFile ? selectedFile.name : 'video.mp4'}`}
          className="mt-2"
        >
      Download Video
        </Button>
      </div>
      ) : null}

        {/* Error Message */}
        {errorMessage && (
          <Alert variant="danger" className="mt-3">
            {errorMessage}
          </Alert>
        )}
      </div>
    </div>
  );
}

export default VideoDetect;
