// src/Logs.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_BASE_URL } from './config';

function Logs() {
  const [logs, setLogs] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/logs`, { withCredentials: true })
      .then((response) => {
        setLogs(response.data.logs);
      })
      .catch((error) => {
        setErrorMessage(
          error.response ? error.response.data.error : 'Server error'
        );
      });
  }, []);

  return (
    <div className="card">
      <h2>Logs</h2>
      {errorMessage && <p className="error">{errorMessage}</p>}
      {logs.length > 0 ? (
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>UID Detected</th>
                <th>Media Checked</th>
                <th>Result</th>
                <th>Checked By</th>
              </tr>
            </thead>
            <tbody>
              {logs.map((log) => (
                <tr key={log._id}>
                  <td>{new Date(log.date).toLocaleString()}</td>
                  <td>{log.uid_detected}</td>
                  <td>{log.media_checked}</td>
                  <td>{log.result}</td>
                  <td>{log.checked_by}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p>No logs available.</p>
      )}
    </div>
  );
}

export default Logs;
