// src/VideoUpload.js

import React, { useState } from 'react';
import axios from 'axios';
import { API_BASE_URL } from './config';
import { Button, Spinner, Alert } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import CustomProgressBar from './CustomProgressBar'; // Import the custom ProgressBar
import './VideoUpload.css'; // Optional: Create this for additional styling

function VideoUpload() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [responseMessage, setResponseMessage] = useState('');
  const [uid, setUid] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [processedVideoUrl, setProcessedVideoUrl] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    setResponseMessage('');
    setUid('');
    setProcessedVideoUrl('');
    setErrorMessage('');
    setUploadProgress(0);
  };

  const handleUpload = () => {
    if (!selectedFile) {
      setErrorMessage('Please select a video file to upload.');
      return;
    }

    const formData = new FormData();
    formData.append('video', selectedFile);

    setIsUploading(true);
    setResponseMessage('');
    setUid('');
    setProcessedVideoUrl('');
    setErrorMessage('');
    setUploadProgress(0);

    axios
      .post(`${API_BASE_URL}/embed`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        withCredentials: true,
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          console.log(`Upload Progress: ${percentCompleted}%`); // For debugging
          setUploadProgress(percentCompleted);
        },
      })
      .then((response) => {
        setResponseMessage(response.data.message);
        setUid(response.data.uid);
        if (response.data.processed_video_url) {
          setProcessedVideoUrl(response.data.processed_video_url);
        }
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.error) {
          setErrorMessage(error.response.data.error);
        } else {
          setErrorMessage('An unexpected error occurred.');
        }
      })
      .finally(() => {
        setIsUploading(false);
      });
  };

  return (
    <div className="container mt-5">
      <div className="card p-4 shadow">
        <h2 className="mb-4">Embed Watermark</h2>
        <div className="mb-3">
          <label htmlFor="videoUpload" className="form-label">
            Select Video File
          </label>
          <input
            type="file"
            accept="video/*"
            onChange={handleFileChange}
            id="videoUpload"
            className="form-control"
          />
          <small className="form-text text-muted">
            Maximum file size: 100 MB.
          </small>
        </div>
        <Button
          variant="primary"
          onClick={handleUpload}
          disabled={isUploading}
        >
          {isUploading ? 'Uploading...' : 'Upload and Embed Watermark'}
        </Button>

        {/* Custom Progress Bar */}
        {uploadProgress > 0 && uploadProgress < 100 && (
          <div className="mt-3">
            <CustomProgressBar progress={uploadProgress} />
          </div>
        )}

        {/* Spinner */}
        {isUploading && uploadProgress === 100 && (
          <div className="mt-3 text-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Processing...</span>
            </Spinner>
            <div>Processing your video...</div>
          </div>
        )}

        {/* Response Message */}
        {responseMessage && (
          <Alert
            variant={responseMessage.toLowerCase().includes('error') ? 'danger' : 'success'}
            className="mt-3"
          >
            {responseMessage}
          </Alert>
        )}

        {/* UID Display */}
        {uid && (
          <p className="uid mt-2">
            Your UID: <strong>{uid}</strong>
          </p>
        )}

        {/* Video Player and Download Button */}
        {processedVideoUrl && (
          <div className="mt-4">
            <h4>Processed Video:</h4>
            <video width="100%" height="auto" controls>
              <source src={processedVideoUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <Button
              variant="success"
              href={processedVideoUrl}
              download={`processed_${selectedFile.name}`}
              className="mt-2"
            >
              Download Video
            </Button>
          </div>
        )}

        {/* Error Message */}
        {errorMessage && (
          <Alert variant="danger" className="mt-3">
            {errorMessage}
          </Alert>
        )}
      </div>
    </div>
  );
}

export default VideoUpload;
