// src/AdminPanel.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_BASE_URL } from './config';
import './AdminPanel.css'; // Import the CSS file

function AdminPanel() {
  const [logs, setLogs] = useState([]);
  const [users, setUsers] = useState([]);
  const [error, setError] = useState('');
  const [newUserUsername, setNewUserUsername] = useState('');
  const [newUserPassword, setNewUserPassword] = useState('');
  const [newUserRole, setNewUserRole] = useState('user');
  const [message, setMessage] = useState('');

  useEffect(() => {
    fetchLogs();
    fetchUsers();
  }, []);

  const fetchLogs = () => {
    axios
      .get(`${API_BASE_URL}/logs`, { withCredentials: true })
      .then((response) => {
        setLogs(response.data.logs);
      })
      .catch((error) => {
        setError('Failed to fetch logs');
        console.error('Error fetching logs:', error);
      });
  };

  const fetchUsers = () => {
    axios
      .get(`${API_BASE_URL}/users`, { withCredentials: true })
      .then((response) => {
        setUsers(response.data.users);
      })
      .catch((error) => {
        setError('Failed to fetch users');
        console.error('Error fetching users:', error);
      });
  };

  const handleCreateUser = (e) => {
    e.preventDefault();
    setError('');
    setMessage('');
    axios
      .post(
        `${API_BASE_URL}/register`,
        {
          username: newUserUsername,
          password: newUserPassword,
          role: newUserRole,
        },
        { withCredentials: true }
      )
      .then((response) => {
        setMessage('User created successfully');
        setNewUserUsername('');
        setNewUserPassword('');
        setNewUserRole('user');
        fetchUsers();
      })
      .catch((error) => {
        setError('Failed to create user');
        console.error('Error creating user:', error);
      });
  };

  return (
    <div className="admin-panel">
      <h2>Admin Panel</h2>
      {error && <p className="error">{error}</p>}
      {message && <p className="message">{message}</p>}

      <div className="section">
        <h3>Create New User</h3>
        <form onSubmit={handleCreateUser} className="user-form">
          <div className="form-group">
            <label>Username:</label>
            <input
              type="text"
              value={newUserUsername}
              onChange={(e) => setNewUserUsername(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>Password:</label>
            <input
              type="password"
              value={newUserPassword}
              onChange={(e) => setNewUserPassword(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>Role:</label>
            <select
              value={newUserRole}
              onChange={(e) => setNewUserRole(e.target.value)}
            >
              <option value="user">User</option>
              <option value="admin">Admin</option>
            </select>
          </div>
          <button type="submit">Create User</button>
        </form>
      </div>

      <div className="section">
        <h3>Users</h3>
        <div className="table-container">
          <table className="users-table">
            <thead>
              <tr>
                <th>Username</th>
                <th>Role</th>
                <th>Created At</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user._id}>
                  <td>{user.username}</td>
                  <td>{user.role}</td>
                  <td>{new Date(user.created_at).toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="section">
        <h3>Logs</h3>
        <div className="table-container">
          <table className="logs-table">
            <thead>
              <tr>
                <th>Date</th>
                <th>UID Detected</th>
                <th>Media Checked</th>
                <th>Result</th>
                <th>Checked By</th>
              </tr>
            </thead>
            <tbody>
              {logs.map((log) => (
                <tr key={log._id}>
                  <td>{new Date(log.date).toLocaleString()}</td>
                  <td>{log.uid_detected}</td>
                  <td>{log.media_checked}</td>
                  <td>{log.result}</td>
                  <td>{log.checked_by}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default AdminPanel;
