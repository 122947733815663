// src/Login.js

import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from './config';
import { Helmet } from 'react-helmet';

function Login({ setAuth, setUserRole }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleLogin = () => {
    axios
      .post(`${API_BASE_URL}/login`, { username, password }, { withCredentials: true })
      .then((response) => {
        setAuth(true);
        setUserRole(response.data.role); // Set user role from response
        navigate('/');
      })
      .catch((error) => {
        setErrorMessage(error.response ? error.response.data.error : 'Server error');
      });
  };

  return (
    <div className="login">
      <Helmet>
        <title>PG - Login</title>
        <meta name="description" content="Login to PG platform" />
      </Helmet>
      <h2>Login</h2>
      <div className="login-form">
        <label htmlFor="username">Username:</label>
        <input
          type="text"
          value={username}
          id="username"
          onChange={(e) => {
            setUsername(e.target.value);
            setErrorMessage('');
          }}
        />
        <label htmlFor="password">Password:</label>
        <input
          type="password"
          value={password}
          id="password"
          onChange={(e) => {
            setPassword(e.target.value);
            setErrorMessage('');
          }}
        />
        <button onClick={handleLogin}>Login</button>
        {errorMessage && <p className="error">{errorMessage}</p>}
      </div>
    </div>
  );
}

export default Login;
