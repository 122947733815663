// src/CustomProgressBar.js

import React from 'react';
import './CustomProgressBar.css'; // Import the custom CSS for styling

function CustomProgressBar({ progress }) {
  return (
    <div className="custom-progress-container">
      <div
        className="custom-progress-filler"
        style={{ width: `${progress}%` }}
      >
        <span className="custom-progress-label">{`${progress}%`}</span>
      </div>
    </div>
  );
}

export default CustomProgressBar;
