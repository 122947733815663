// src/App.js

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, NavLink, Navigate } from 'react-router-dom';
import VideoUpload from './VideoUpload';
import VideoDetect from './VideoDetect';
import Logs from './Logs';
import AdminPanel from './AdminPanel'; // Import AdminPanel component
import Login from './Login';
import axios from 'axios';
import { API_BASE_URL } from './config';
import logo from './logo.png';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState(null); // State to hold the user's role
  const [menuOpen, setMenuOpen] = useState(false);

  // Check authentication status on initial load
  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/check_auth`, { withCredentials: true })
      .then((response) => {
        setIsAuthenticated(response.data.authenticated);
        if (response.data.authenticated) {
          setUserRole(response.data.role); // Set the user's role
        }
      })
      .catch(() => {
        setIsAuthenticated(false);
        setUserRole(null);
      });
  }, []);

  const handleLogout = () => {
    axios
      .post(`${API_BASE_URL}/logout`, {}, { withCredentials: true })
      .then(() => {
        setIsAuthenticated(false);
        setUserRole(null); // Reset user role on logout
      })
      .catch((error) => {
        console.error('Logout error:', error);
      });
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <Router>
      <div className="app">
        <nav className="navbar">
          <img src={logo} alt="Logo" className="logo" />
          {isAuthenticated && (
            <button className="menu-toggle" onClick={toggleMenu} aria-label="Toggle navigation">
              <span className="bar"></span>
              <span className="bar"></span>
              <span className="bar"></span>
            </button>
          )}
          <ul className={`nav-links ${menuOpen ? 'open' : ''}`}>
            {isAuthenticated ? (
              <>
                <li>
                  <NavLink to="/" className={({ isActive }) => (isActive ? 'active' : '')} onClick={toggleMenu}>
                    Embed Watermark
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/detect" className={({ isActive }) => (isActive ? 'active' : '')} onClick={toggleMenu}>
                    Detect Watermark
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/logs" className={({ isActive }) => (isActive ? 'active' : '')} onClick={toggleMenu}>
                    View Logs
                  </NavLink>
                </li>
                {userRole === 'admin' && (
                  <li>
                    <NavLink to="/admin" className={({ isActive }) => (isActive ? 'active' : '')} onClick={toggleMenu}>
                      Admin Panel
                    </NavLink>
                  </li>
                )}
                <li>
                  <button className="logout-button" onClick={handleLogout}>
                    Logout
                  </button>
                </li>
              </>
            ) : (
              <li>
                <NavLink to="/login" className={({ isActive }) => (isActive ? 'active' : '')}>
                  Login
                </NavLink>
              </li>
            )}
          </ul>
        </nav>
        <div className="content">
          <Routes>
            <Route
              path="/"
              element={
                isAuthenticated ? (
                  <VideoUpload />
                ) : (
                  <Navigate to="/login" replace />
                )
              }
            />
            <Route
              path="/detect"
              element={
                isAuthenticated ? (
                  <VideoDetect />
                ) : (
                  <Navigate to="/login" replace />
                )
              }
            />
            <Route
              path="/logs"
              element={
                isAuthenticated ? (
                  <Logs />
                ) : (
                  <Navigate to="/login" replace />
                )
              }
            />
            <Route
              path="/admin"
              element={
                isAuthenticated && userRole === 'admin' ? (
                  <AdminPanel />
                ) : (
                  <Navigate to="/login" replace />
                )
              }
            />
            <Route
              path="/login"
              element={
                isAuthenticated ? (
                  <Navigate to="/" replace />
                ) : (
                  <Login setAuth={setIsAuthenticated} setUserRole={setUserRole} />
                )
              }
            />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
